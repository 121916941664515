:root {
  --surface: #FBF9F9;
  --surface-variant: #464747;
  --surface-container: #E3E2E2;
  --outline: #777777;
  --error: #F04438;
  --error-container: #FCDFDD;
  --warning: #EAAA08;
  --warning-container: #FBF1D5;
  --success: #66C61C;
  --success-container: #E5F5D8;

  //#region primary

  --primary: #FF8001;
  --primary-100: #FFFFFF;
  --primary-99: #FFFCFA;
  --primary-98: #FFFAF6;
  --primary-95: #FFF4E9;
  --primary-90: #FFE9D4;
  --primary-80: #FFD4AA;
  --primary-70: #FFBF7F;
  --primary-60: #FFAA55;
  --primary-50: #FF952B;
  --primary-40: #FF8001;
  --primary-35: #DF7001;
  --primary-30: #BF6001;
  --primary-25: #9F5001;
  --primary-20: #804000;
  --primary-15: #603000;
  --primary-10: #402000;
  --primary-5: #201000;
  --primary-0: #000000;

  //#endregion

  //#region secondary
  --secondary: #FFB301;
  --secondary-container: #FFF2D4;
  --secondary-container-ripple: #fff2d418;
  --secondary-100: #FFFFFF;
  --secondary-99: #FFFDFA;
  --secondary-98: #FFFCF6;
  --secondary-95: #FFF8E9;
  --secondary-90: #FFF2D4;
  --secondary-80: #FFE5AA;
  --secondary-70: #FFD97F;
  --secondary-60: #FFCC55;
  --secondary-50: #FFC02B;
  --secondary-40: #FFB301;
  --secondary-35: #DF9D01;
  --secondary-30: #BF8601;
  --secondary-25: #9F7001;
  --secondary-20: #805900;
  --secondary-15: #604300;
  --secondary-10: #402D00;
  --secondary-5: #201600;
  --secondary-0: #000000;
  //#endregion

  //#region tertiary
  --tertiary: #2A2A2A;
  --tertiary-100: #FFFFFF;
  --tertiary-99: #FBFBFB;
  --tertiary-98: #F7F7F7;
  --tertiary-95: #ECECEC;
  --tertiary-90: #DBDBDB;
  --tertiary-80: #B7B7B7;
  --tertiary-70: #949494;
  --tertiary-60: #717171;
  --tertiary-50: #4D4D4D;
  --tertiary-40: #2A2A2A;
  --tertiary-35: #252525;
  --tertiary-30: #1F1F1F;
  --tertiary-25: #1A1A1A;
  --tertiary-20: #151515;
  --tertiary-15: #101010;
  --tertiary-10: #0A0A0A;
  --tertiary-5: #050505;
  --tertiary-0: #000000;
  //#endregion


  //#region neutral
  --neutral-100: #FFFFFF;
  --neutral-99: #FEFCFB;
  --neutral-98: #FBF9F9;
  --neutral-95: #F2F0F0;
  --neutral-90: #E3E2E2;
  --neutral-80: #C7C6C6;
  --neutral-70: #ACABAB;
  --neutral-60: #919090;
  --neutral-50: #777777;
  --neutral-40: #5E5E5E;
  --neutral-35: #525252;
  --neutral-30: #464747;
  --neutral-25: #3B3B3C;
  --neutral-20: #303031;
  --neutral-15: #252626;
  --neutral-10: #1B1C1C;
  --neutral-5: #101111;
  --neutral-0: #000000;
  //#endregion

}
