:root {
    --width-0: 0px;
    --width-0_5: 2px;
    --width-1: 4px;
    --width-1_5: 6px;
    --width-2: 8px;
    --width-3: 12px;
    --width-4: 16px;
    --width-5: 20px;
    --width-6: 24px;
    --width-8: 32px;
    --width-10: 40px;
    --width-12: 48px;
    --width-16: 64px;
    --width-20: 80px;
    --width-24: 96px;
    --width-32: 128px;
    --width-40: 160px;
    --width-48: 192px;
    --width-56: 224px;
    --width-64: 256px;
    --width-80: 320px;
    --width-96: 384px;
    --width-120: 480px;
    --width-140: 560px;
    --width-160: 640px;
    --width-180: 720px;
    --width-192: 768px;
    --width-256: 1024px;
    --width-320: 1280px;
    --width-360: 1440px;
    --width-400: 1600px;
    --width-480: 1920px;
}