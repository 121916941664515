:root {
    --spacing-none: 0px;
    --spacing-xxs: 4px;
    --spacing-xs: 8px;
    --spacing-sm: 12px;
    --spacing-md: 16px;
    --spacing-lg: 20px;
    --spacing-xl: 24px;
    --spacing-2xl: 32px;
    --spacing-3xl: 40px;
    --spacing-4xl: 48px;
    --spacing-5xl: 64px;
    --spacing-5xl: 80px;
    --spacing-7xl: 96px;
    --spacing-8xl: 128px;
    --spacing-9xl: 160px;
    --spacing-10xl: 192px;
    --spacing-11xl: 224px;
    --spacing-12xl: 256px;
    --spacing-13xl: 320px;
}