:root {
  //#region inputs
  --mat-form-field-container-text-font: Roboto;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.5px;
  --mat-form-field-container-text-weight: 400;
  --mdc-outlined-text-field-outline-color: #E3E2E2;
  .mat-form-field-disabled > .mdc-text-field{
    background-color: var(--neutral-95);
  }
  .mdc-floating-label--float-above{
    background-color: var(--surface);
  }
  //#endregion

  //#region button
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 6px;
  .mat-mdc-button{
    border-radius: 6px;
  }
  //#endregion

  //#region select-panel
  --mat-select-panel-background-color: white;
  //#endregion

  //#region datepicker
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 1px 8px 0px rgb(152 152 152 / 93%);
  --mat-datepicker-calendar-container-shape: 4px;
  //#endregion

  //#region table
  --mat-table-background-color: #FFFFFF;
  --mat-table-header-headline-color: #1B1C1C;
  --mat-table-row-item-outline-color: #E3E2E2;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 700;


  thead {
    background-color: #FBF9F9 !important;
  }

  th,
  td {
    text-align: center;
  }

  //#endregion

  //#region table
  --mat-paginator-container-background-color: #FFFFFF;
  --mat-paginator-container-text-color: #777777;

  mat-paginator {
    margin-top: 47px;

    .mat-mdc-paginator-container {
      justify-content: center;
    }
  }

  //#endregion

  //#region stepper

  //makes stepper container background inherit
  mat-stepper {
    background: inherit;
  }

  .mat-stepper-horizontal-line {
    border-top-color: var(--neutral-80);
    margin: 0;
  }

  .mat-step-icon {
    background-color: var(--outline);
  }

  .mat-step-icon-selected {
    background-color: var(--primary);
    color: var(--primary-100);
  }

  .mat-step-icon-state-done {
    background-color: transparent;
  }

  .mat-step-header.cdk-program-focused,
  .mat-step-header.cdk-keyboard-focused {
    background-color: transparent;
  }

  .mat-horizontal-stepper-header {
    padding: 0 16px;
  }

  //#endregion

  //#region dialog

  .mat-mdc-dialog-surface {
    border-radius: var(--radius-sm);
  }

  //#endregion

  //#region auto_complete

  .mat-mdc-autocomplete-panel {
    background-color: var(--surface) !important;
  }

  //#endregion
}
