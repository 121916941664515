.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.pointer {
    cursor: pointer;
}

.container {
    padding: var(--spacing-xl) var(--spacing-md);
    border-radius: var(--radius-sm);
    border: 1px solid var(--surface-container);
    margin-bottom: var(--spacing-md);


}

.form {
    .form-item {
        display: flex;
        flex-wrap: wrap;
        gap: var(--spacing-2xl);

        .item {
            flex: 1 1 22%;
            min-width: 200px;
        }

        .item-block {
            flex: 1 1 100%;
        }

        .item-half {
            flex: 1 1 auto;
        }

        .item-one-third {
            flex: 0 0 calc(33.33333333% - var(--spacing-2xl) * 2 / 3);
        }

        .item-two-third {
            flex: 0 0 calc(67.5% - var(--spacing-2xl) * 2 / 3)
        }
    }
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.text-end {
    text-align: end;
}

.select-custom-arrow {
    .mat-mdc-select-arrow {
        svg {
            display: none;
        }

        &::before {
            content: "";
            display: block;
            width: 9px;
            height: 9px;
            transform: translate(-50%, -50%) rotate(45deg);
            border-width: 0 1.5px 1.5px 0;
            border-style: solid;
            border-color: #000000bf;
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }
}

.explorer-url {
    color: var(--outline);
    cursor: pointer;
    margin-left: 6px;

    &:hover {

        color: var(--primary) !important;
    }
}