//#region display
.display-large {
    font-size: 25px;
    line-height: 64px;
    letter-spacing: -0.25px;
    font-weight: normal;
}

.display-large-bold {
    font-size: 25px;
    line-height: 64px;
    letter-spacing: -0.25px;
    font-weight: bold;
}

.display-medium {
    font-size: 45px;
    line-height: 52px;
    letter-spacing: 0px;
    font-weight: normal;
}

.display-medium-bold {
    font-size: 45px;
    line-height: 52px;
    letter-spacing: 0px;
    font-weight: bold;
}

.display-small {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0px;
    font-weight: normal;
}

.display-small-bold {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0px;
    font-weight: bold;
}

//#endregion


//#region headline

.headline-large {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0px;
    font-weight: normal;
}

.headline-large-bold {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0px;
    font-weight: bold;
}

.headline-medium {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0px;
    font-weight: normal;
}

.headline-medium-bold {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0px;
    font-weight: bold;
}

.headline-small {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    font-weight: normal;
}

.headline-small-bold {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    font-weight: bold;
}

//#endregion


//#region title

.title-large {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0px;
    font-weight: normal;
}

.title-large-bold {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0px;
    font-weight: bold;
}

.title-medium {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: normal;
}

.title-medium-bold {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: bold;
}

.title-small {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: normal;
}

.title-small-bold {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: bold;
}

//#endregion


//#region body

.body-large {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-weight: normal;
}

.body-large-bold {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-weight: bold;
}

.body-medium {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: normal;
}

.body-medium-bold {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: bold;
}

.body-small {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-weight: normal;
}

.body-small-bold {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-weight: bold;
}

//#endregion


//#region label

.label-large {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: normal;
}

.label-large-bold {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: bold;
}

.label-medium {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    font-weight: normal;
}

.label-medium-bold {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    font-weight: bold;
}

.label-small {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    font-weight: normal;
}

.label-small-bold {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    font-weight: bold;
}

//#endregion