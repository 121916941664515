@use"../src/app/shared/styles/material.scss";
@use"../src/app/shared/styles/material-customize.scss";
@use"../src/app/shared/styles/colors.scss";
@use"../src/app/shared/styles/general.scss";
@use"../src/app/shared/styles/spacing.scss";
@use"../src/app/shared/styles/typography.scss";
@use"../src/app/shared/styles/width.scss";
@use"../src/app/shared/styles/fonts.scss";
@use"../src/app/shared/styles/corner-radius.scss";


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font-family-en), "Helvetica Neue", sans-serif !important;
}

.success {
  --mdc-snackbar-container-color: #66C61C;
  --mdc-snackbar-supporting-text-color: #FFFFFF;
}

.failed {
  --mdc-snackbar-container-color: #F04438;
  --mdc-snackbar-supporting-text-color: #FFFFFF;
}